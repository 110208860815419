// Auto-generated RPC interface from /opt/wh/whtree/modules/tollium/web/ui/js/comm/toddservice.rpc.json
var RPCClient = require("@mod-system/js/wh/rpc").default;
var request = exports.rpcclient = new RPCClient("tollium:todd");
exports.rpcResolve = function(promise, result) { request._handleLegacyRPCResolve(promise, result) };
exports.invoke = function() { return request.invoke.apply(request,Array.prototype.slice.call(arguments)); }

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/wh/whtree/modules/tollium/lib/todd/internal/service.whlib'

exports.runToddComm = exports.RunToddComm = /*RECORD*/function(/*RECORD*/ request)
{
return request.invoke.apply(request,["RunToddComm"].concat(Array.prototype.slice.call(arguments)));
}

exports.retrieveImages = exports.RetrieveImages = /*RECORD*/function(/*RECORD ARRAY*/ images, /*BOOLEAN*/ nocache)
{
return request.invoke.apply(request,["RetrieveImages"].concat(Array.prototype.slice.call(arguments)));
}
